.typingtest {
    width: 100%;
    height: 100%;
    margin-top: 4%;
}

.vcenterflex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 16em;
    height: 25em;
    color: white;
}

.typingtest:focus .textwindow {
    border: 2px solid lightskyblue;
}

.textwindow {
    width: 50%;
    height: 80%;
    padding: 1em;
    border: 2px solid black;
    background-color: #282c34;
    font-size: 1.4em;
}

.statsdisplay {
    font-weight: bold;
    color: white;
    width: 18em;
}

.quoteLeft {
    background-color: #050;
    white-space: pre-wrap;
}

.quoteError {
    background-color: #f70;
    white-space: pre-wrap;
}

.quoteRight {
    white-space: pre-wrap;
    
}